<template>

  <div v-if="isShow">
    <loading/>
  </div>
<div class="Member-bg">
<div class="middle common-padding-half">
  <div class="point">
    <h1>積分明細</h1>
    <div class="pointNumber">
      <h1>{{ points }}<span>點</span></h1>
    </div>

    <div class="point-cate">
      <div @click="onStatusChange(0)" :class="{ active: type === 0}">
        全部
      </div>
      <div @click="onStatusChange(1)" :class="{ active: type === 1}">
        增加
      </div>
      <div @click="onStatusChange(2)" :class="{ active: type === 2}">
        減少
      </div>
    </div>

    <div class="point-list" v-if="list.length > 0">
       <ul>
         <li v-for="item in list" :key="item.id">
           <div>{{item.created_at}}</div>
           <div class="point-list-li">
              <span v-if="item.type === 1">
                +  {{ item.calculus }}
              </span>
               <span class="count" v-else>
                 - {{ item.calculus }}
              </span>
           </div>
         </li>
       </ul>
    </div>

    <Pagination
        :current-page="currentPage"
        :total-pages="totalPages"
        :show-pagination="showPagination"
        @goto-page="onPageChange"
    />


  </div>
</div>
</div>
</template>

<script>
import {getPoint} from "@/network/main";
import Loading from "@/components/loading";
import Pagination from "@/components/Pagination";

export default {
  name: "Points",
  components:{
    Pagination,
    Loading
  },
  data(){
    return{
      isShow:true,
      points:'',
      list:[],
      currentPage: 1, // 当前页码
      totalPages: 1, // 总页数
      showPagination: true, // 是否显示分页
      type: 0 // 默认选择全部
    }
  },
  created() {
    this.type = 0; // 默认选择全部
    this.fetchData(this.type);
  },
  methods:{
    fetchData(type) {
      this.type = type
      getPoint(this.type,this.currentPage).then(res =>{
        this.isShow = false
        this.points = res.data.data.calculus;
        this.list = res.data.data.data;
        this.type = type;
        this.currentPage = res.data.data.current_page;
        this.totalPages = res.data.data.last_page;
        this.showPagination = this.totalPages > 1; // 是否显示分页
      })
    },
    //点击分页按钮
    onPageChange(page) {
      this.currentPage = page;
      this.fetchData(this.type);
      window.scrollTo(0, 0);
    },
    onStatusChange(type) {
      this.type = type;
      this.currentPage = 1;
      this.fetchData(type);
    }
  },

}
</script>

<style scoped>

</style>